import React from "react";
import { useParams } from "react-router-dom";

// components
import Template1 from "../components/Cards/Template1";
import Template2 from "../components/Cards/Template2";
import Template3 from "../components/Cards/Template3";
import Template4 from "../components/Cards/Template4";
import Template5 from "../components/Cards/Template5";

function ViewCard() {
  const params = useParams();

  if (params.templateName === "Template-1") {
    return <Template1 />;
  } else if (params.templateName === "Template-2") {
    return <Template2 />;
  } else if (params.templateName === "Template-3") {
    return <Template3 />;
  } else if (params.templateName === "Template-4") {
    return <Template4 />;
  } else if (params.templateName === "Template-5") {
    return <Template5 />;
  }
}

export default ViewCard;
