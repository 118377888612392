import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";

import ClassicTheme from "../../assets/themeBg/classic.png";
import CeramicWhiteTheme from "../../assets/themeBg/ceramic_white.png";

// services
import cardServices from "../../services/card.service";

// components
import Dashboard from "../Dashboard";

function Template3Editor() {
  const dispatch = useDispatch();
  const params = useParams();
  const cards = useSelector((state) => state.cards);

  const [showFrontSide, setShowFrontSide] = useState(true);
  const [data, setData] = useState({
    _id: "",
    theme: "classic",
    firstName: "",
    lastName: "",
    organization: "",
  });

  useEffect(() => {
    const retrivedData = cards.filter((dt) => dt._id === params.cardId)[0];
    if (retrivedData) {
      setData({
        _id: retrivedData._id,
        theme: retrivedData.theme,
        firstName: retrivedData.firstName,
        lastName: retrivedData.lastName,
        organization: retrivedData.organization,
      });
    }
  }, [cards]);

  const handleChange = async (e) => {
    const { name, value } = e.target;

    setData((prev) => ({ ...prev, [name]: value }));
  };

  const handleUpdate = async () => {
    try {
      const response = await dispatch(
        cardServices.update(params.templateName, data._id, data)
      );
      if (response.success) {
        toast.success(response.status);
      } else {
        toast.success(response.status);
      }
    } catch (err) {
      console.log(err);
      let error = err.response.data.error || err;
      let status = err.response.data.status;
      toast.error(error.message || status, {});
    }
  };

  return (
    <div className="mt-6 flex flex-col items-center justify-center">
      <div className="w-full flex justify-end space-x-3">
        <a
          href={`/view/${params.templateName}/${params.cardId}`}
          target="_blank"
          className="relative inline-flex items-center justify-center p-0.5 mb-2 mr-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-red-200 via-red-300 to-yellow-200 group-hover:from-red-200 group-hover:via-red-300 group-hover:to-yellow-200 dark:text-white dark:hover:text-gray-900 focus:ring-4 focus:outline-none focus:ring-red-100 dark:focus:ring-red-400"
        >
          <span className="relative px-5 py-2.5 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
            View
          </span>
        </a>
        <button
          className="relative inline-flex items-center justify-center p-0.5 mb-2 mr-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-red-200 via-red-300 to-yellow-200 group-hover:from-red-200 group-hover:via-red-300 group-hover:to-yellow-200 dark:text-white dark:hover:text-gray-900 focus:ring-4 focus:outline-none focus:ring-red-100 dark:focus:ring-red-400"
          onClick={handleUpdate}
        >
          <span className="relative px-5 py-2.5 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
            Save
          </span>
        </button>
      </div>

      <ul className="flex justify-center items-center space-x-6">
        <li>
          {" "}
          <button
            className={`p-2 border-black ${
              data.theme === "classic"
                ? "text-black border-b-[1px]"
                : "text-[#8D8D8D]"
            } text-lg font-normal`}
            onClick={() =>
              handleChange({ target: { name: "theme", value: "classic" } })
            }
          >
            Classic
          </button>{" "}
        </li>
        <li>
          {" "}
          <button
            className={`p-2 border-black ${
              data.theme === "ceramic_white"
                ? "text-black border-b-[1px]"
                : "text-[#8D8D8D]"
            } text-lg font-normal`}
            onClick={() =>
              handleChange({
                target: { name: "theme", value: "ceramic_white" },
              })
            }
          >
            Ceramic White
          </button>{" "}
        </li>
        <li>
          {" "}
          <button
            className={`p-2 border-black ${
              data.theme === "classic_gold"
                ? "text-black border-b-[1px]"
                : "text-[#8D8D8D]"
            } text-lg font-normal`}
            onClick={() =>
              handleChange({ target: { name: "theme", value: "classic_gold" } })
            }
          >
            Classic Gold
          </button>{" "}
        </li>
      </ul>

      <div className="editor_card relative w-[275px] h-[160px] sm:w-[300px] sm:h-[175px] md:w-[400px] md:h-[235px] my-12 mx-auto">
        <div
          className="flip_inner box-shadow"
          style={!showFrontSide ? { transform: "rotateY(180deg)" } : {}}
        >
          <div
            className="flip_card absolute h-full w-full rounded-xl"
            style={{
              backgroundImage: `url(${
                data.theme === "ceramic_white"
                  ? CeramicWhiteTheme
                  : ClassicTheme
              })`,
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }}
          >
            <div className="absolute left-5 bottom-5 sm:left-7 sm:bottom-6 md:left-9 md:bottom-7 flex flex-col text-white">
              <span
                className={`${
                  data.theme === "classic"
                    ? "gradient-text"
                    : "gradient_gold-text"
                } text-inherit text-lg sm:text-2xl md:text-3xl font-bold`}
              >
                {" "}
                {data.firstName} {data.lastName}{" "}
              </span>
              <span
                className={`${
                  data.theme === "classic"
                    ? "gradient-text"
                    : "gradient_gold-text"
                } text-inherit text-xs sm:text-sm md:text-base font-semibold`}
              >
                {" "}
                {data.organization}{" "}
              </span>
            </div>
          </div>
          <div
            className="flip_card absolute h-full w-full rounded-xl"
            style={{
              transform: "rotateY(180deg)",
              backgroundImage: `url(${
                data.theme === "ceramic_white"
                  ? CeramicWhiteTheme
                  : ClassicTheme
              })`,
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }}
          >
            <div className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2">
              <img
                className="w-12 sm:w-14 md:w-16"
                src="/static/images/savage-sales-logo.png"
                alt="Savage Sales"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="w-full px-4 md:px-8 flex flex-col space-y-3 items-center">
        <button
          className="flex items-center space-x-2"
          onClick={() => setShowFrontSide((prev) => !prev)}
        >
          <span className="text-sm"> Flip </span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
            />
          </svg>
        </button>
        <div className="w-full grid grid-cols-1 md:grid-cols-3 gap-3 md:gap-6">
          <div className="w-full">
            <input
              className="w-full p-2 text-base border-2 border-[#ddd] shadow-inner outline-none focus:border-[#80bdff] transition-all duration-200 ease-in-out"
              type="text"
              name="firstName"
              placeholder="Your firstName"
              value={data.firstName}
              onChange={handleChange}
            />
          </div>
          <div className="w-full">
            <input
              className="w-full p-2 text-base border-2 border-[#ddd] shadow-inner outline-none focus:border-[#80bdff] transition-all duration-200 ease-in-out"
              type="text"
              name="lastName"
              placeholder="Your lastName"
              value={data.lastName}
              onChange={handleChange}
            />
          </div>
          <div className="w-full">
            <input
              className="w-full p-2 text-base border-2 border-[#ddd] shadow-inner outline-none focus:border-[#80bdff] transition-all duration-200 ease-in-out"
              type="text"
              name="organization"
              placeholder="Your organization"
              value={data.organization}
              onChange={handleChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Template3Editor;
