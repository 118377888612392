// modules
import axios from "../utils/axios";

// slices
import { setUser, removeUser, updateUser } from "../utils/reducers/userSlice";
import { setCards } from "../utils/reducers/cardSlice";

const authServices = {
  getUser: () => (dispatch) => {
    return axios({
      url: "/api/users/getUser",
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    }).then((res) => {
      const resData = res.data;

      if (resData.success) {
        dispatch(
          setUser({ toke: resData.data.token, user: resData.data.user })
        );
        dispatch(setCards(resData.data.cards));
        return {
          success: true,
        };
      } else {
        return {
          success: false,
          status: resData.status,
        };
      }
    });
  },
  register: (data) => {
    return axios({
      url: "/api/users/register",
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    }).then((response) => {
      const resData = response.data;

      if (resData.success) {
        return {
          success: true,
          status: resData.status,
        };
      } else {
        return {
          success: false,
          status: resData.status,
        };
      }
    });
  },
  login: (email, password) => (dispatch) => {
    return axios({
      url: "/api/users/login",
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        email,
        password,
      },
    }).then((response) => {
      const resData = response.data;

      if (resData.success) {
        dispatch(
          setUser({ toke: resData.data.token, user: resData.data.user })
        );
        dispatch(setCards(resData.data.cards));
        return {
          success: true,
          status: resData.status,
        };
      } else {
        return {
          success: false,
          status: resData.status,
        };
      }
    });
  },
  logout: () => (dispatch) => {
    return axios({
      url: "/api/users/logout",
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    }).then((response) => {
      const resData = response.data;

      if (resData.success) {
        dispatch(removeUser());
        return {
          success: true,
          status: resData.status,
        };
      } else {
        return {
          success: false,
          status: resData.status,
        };
      }
    });
  },
  update: (userId, data) => (dispatch) => {
    return axios({
      url: "/api/users/update/" + userId,
      method: "put",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    }).then((response) => {
      const resData = response.data;

      if (resData.success) {
        dispatch(updateUser(resData.data));
        return {
          success: true,
          status: resData.status,
        };
      } else {
        return {
          success: false,
          status: resData.status,
        };
      }
    });
  },
};

export default authServices;
