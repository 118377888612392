import React, { useEffect } from "react";
import "./styles/app.css";
import "react-toastify/dist/ReactToastify.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";

// services
import authServices from "./services/auth.service";

// components
import Login from "./pages/auth/Login";
import Register from "./pages/auth/Register";

import EditorCard from "./components/Editor/Template4.Editor";
import Dashboard from "./components/Dashboard";
import AllCards from "./pages/dashboard/AllCards";
import Profile from "./pages/dashboard/Profile";
import Edit from "./pages/dashboard/Edit";
import ViewCard from "./pages/ViewCard";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/dashboard">
          <Route
            path=""
            element={
              <AuthWrapper>
                <Dashboard />
              </AuthWrapper>
            }
          />
          <Route
            path="profile"
            element={
              <AuthWrapper>
                <Profile />
              </AuthWrapper>
            }
          />
          <Route
            path="cards"
            element={
              <AuthWrapper>
                <AllCards />
              </AuthWrapper>
            }
          />
          <Route
            path="edit/:templateName/:cardId"
            element={
              <AuthWrapper>
                <Edit />
              </AuthWrapper>
            }
          />
        </Route>
        <Route path="/view/:templateName/:cardId" element={<ViewCard />} />
        <Route path="/editor" element={<EditorCard />} />
      </Routes>
      <ToastContainer />
    </Router>
  );
}

const AuthWrapper = ({ children }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  useEffect(() => {
    if (!user) {
      (async () => {
        try {
          const response = await dispatch(authServices.getUser());

          if (response.success) {
            navigate("/dashboard/cards", { replace: true });
          } else {
            navigate("/", { replace: true });
          }
        } catch (err) {
          navigate("/", { replace: true });
        }
      })();
    }
  }, []);

  if (!user) return;

  return children;
};

export default App;
