import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import coverPlaceholder from "../../assets/images/cover_placeholder.png";
import profilePlaceholder from "../../assets/images/profile_placeholder.png";
import organizationPlaceholder from "../../assets/images/logo_placeholder.png";

// services
import cardServices from "../../services/card.service";

function Template2() {
  const params = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState({
    name: "",
    profession: "",
    organization: "",
    email: "",
    phone: "",
    facebook: "",
    instagram: "",
    telegram: "",
    whatsapp: "",
    bookMeeting: "",
    coverImage: "",
    profileImage: "",
    organizationImage: "",
    workTypes: [],
    bio: "",
  });

  useEffect(() => {
    {
      (async () => {
        try {
          const response = await cardServices.getById(
            params.templateName,
            params.cardId
          );
          setIsLoading(false);
          if (response.success) {
            setData({
              name: response.data.name,
              profession: response.data.profession,
              organization: response.data.organization,
              email: response.data.email,
              phone: response.data.phone,
              facebook: response.data.facebook,
              instagram: response.data.instagram,
              telegram: response.data.telegram,
              whatsapp: response.data.whatsapp,
              bookMeeting: response.data.bookMeeting,
              coverImage: response.data.coverImage,
              profileImage: response.data.profileImage,
              organizationImage: response.data.organizationImage,
              workTypes: response.data.workTypes,
              bio: response.data.bio,
            });
          } else {
            toast.success(response.status);
          }
        } catch (err) {
          setIsLoading(false);
          console.log(err);
          let error = err.response.data.error || err;
          let status = err.response.data.status;
          toast.error(error.message || status, {});
        }
      })();
    }
  }, []);

  //   if (isLoading) {
  //     return <Loader />;
  //   }

  return (
    <div className="flex flex-col items-center justify-center">
      <div className="editor_card box-shadow max-w-[440px] w-full sm:my-12 bg-white sm:rounded-xl overflow-hidden">
        <header className="relative w-full">
          <img
            className="w-full object-contain"
            alt={data.name}
            src={data.coverImage || coverPlaceholder}
          />

          <div className="absolute left-3 md:left-4 -bottom-[20%] h-28 w-28 md:h-32 md:w-32 bg-white rounded-full overflow-hidden transition-all duration-200 ease-in-out transform hover:scale-105">
            <span className="block p-2 w-full h-full overflow-hidden">
              <img
                className="w-full h-full object-contain"
                src={data.profileImage || profilePlaceholder}
                alt={data.name}
              />
            </span>
          </div>
          <div className="absolute right-3 md:right-4 -bottom-[20%] h-28 w-36 md:h-32 md:w-40 bg-white rounded-lg overflow-hidden transition-all duration-200 ease-in-out transform hover:scale-105">
            <span className="block p-2 h-full w-full overflow-hidden">
              <img
                className="w-full h-full object-contain"
                src={data.organizationImage || organizationPlaceholder}
                alt={data.organization}
              />
            </span>
          </div>
        </header>
        <main className="mt-24">
          {/* first part of body*/}
          <div className="mx-6">
            <h1 className="text-3xl text-[#35393b] font-bold">{data.name}</h1>

            <div className="mt-3 text-2xl text-[#35393b] font-medium">
              <span className="whitespace-pre-wrap">{data.profession}</span>
            </div>

            <div className="text-2xl text-[#35393b] font-medium">
              <span className="whitespace-pre-wrap">{data.organization}</span>
            </div>

            <ul className="mt-3 flex flex-wrap items-center gap-2">
              {data.workTypes.map((dt, idx) => (
                <li key={idx}>
                  {" "}
                  <span className="px-2 py-1 text-inherit text-sm font-bold rounded-md bg-[#e6e8ea] hover:bg-[#d5dadd] transition-all duration-200 ease-in-out">
                    {dt}
                  </span>{" "}
                </li>
              ))}
            </ul>

            <div className="mt-5 text-lg text-[#525f66] font-medium">
              <span className="">{data.bio}</span>
            </div>
          </div>

          {/* secord part of body - buttons */}
          <ul className="mt-6 mb-3 flex flex-col">
            <li>
              <a
                href={`mailto:${data.email}`}
                className="px-6 pt-3 pb-2 flex items-center space-x-4 hover:bg-[#f9f7f7] transition-all ease-linear"
              >
                <button className="p-2 text-white bg-[#4a4a4a] rounded-full">
                  <svg
                    ariaHidden="true"
                    focusable="false"
                    className="h-6 w-6 text-inherit fill-current overflow-visible"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                  >
                    <path d="M20 4H4C2.9 4 2.01 4.9 2.01 6L2 18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V6C22 4.9 21.1 4 20 4ZM19.6 8.25L12.53 12.67C12.21 12.87 11.79 12.87 11.47 12.67L4.4 8.25C4.15 8.09 4 7.82 4 7.53C4 6.86 4.73 6.46 5.3 6.81L12 11L18.7 6.81C19.27 6.46 20 6.86 20 7.53C20 7.82 19.85 8.09 19.6 8.25Z"></path>
                  </svg>
                </button>
                <div className="flex flex-col">
                  <div className="text-base text-[#35393B] font-bold text-ellipsis overflow-hidden">
                    <span>{data.email}</span>
                  </div>
                  <div className="text-sm text-[#525f66] font-thin">
                    <span>Email</span>
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a
                href={`tel:${data.phone}`}
                className="px-6 py-2 flex items-center space-x-4 hover:bg-[#f9f7f7] transition-all ease-linear"
              >
                <button className="p-2 text-white bg-[#4a4a4a] rounded-full">
                  <svg
                    ariaHidden="true"
                    focusable="false"
                    className="h-6 w-6 text-inherit fill-current overflow-visible"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                  >
                    <path d="M19.23 15.26L16.69 14.97C16.08 14.9 15.48 15.11 15.05 15.54L13.21 17.38C10.38 15.94 8.06004 13.63 6.62004 10.79L8.47004 8.94001C8.90004 8.51001 9.11004 7.91001 9.04004 7.30001L8.75004 4.78001C8.63004 3.77001 7.78004 3.01001 6.76004 3.01001H5.03004C3.90004 3.01001 2.96004 3.95001 3.03004 5.08001C3.56004 13.62 10.39 20.44 18.92 20.97C20.05 21.04 20.99 20.1 20.99 18.97V17.24C21 16.23 20.24 15.38 19.23 15.26V15.26Z"></path>
                  </svg>
                </button>
                <div className="flex flex-col">
                  <div className="text-base text-[#35393B] font-bold text-ellipsis overflow-hidden">
                    <span>{data.phone}</span>
                  </div>
                  <div className="text-sm text-[#525f66] font-thin">
                    <span>Cell</span>
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a
                href={data.instagram}
                target="_blank"
                className="px-6 pt-2 pb-3 flex items-center space-x-4 hover:bg-[#f9f7f7] transition-all ease-linear"
              >
                <button className="p-2 text-white bg-[#4a4a4a] rounded-full">
                  <svg
                    ariaHidden="true"
                    focusable="false"
                    className="h-6 w-6 text-inherit fill-current overflow-visible"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                  >
                    <path d="M7.8 2H16.2C19.4 2 22 4.6 22 7.8V16.2C22 17.7383 21.3889 19.2135 20.3012 20.3012C19.2135 21.3889 17.7383 22 16.2 22H7.8C4.6 22 2 19.4 2 16.2V7.8C2 6.26174 2.61107 4.78649 3.69878 3.69878C4.78649 2.61107 6.26174 2 7.8 2V2ZM7.6 4C6.64522 4 5.72955 4.37928 5.05442 5.05442C4.37928 5.72955 4 6.64522 4 7.6V16.4C4 18.39 5.61 20 7.6 20H16.4C17.3548 20 18.2705 19.6207 18.9456 18.9456C19.6207 18.2705 20 17.3548 20 16.4V7.6C20 5.61 18.39 4 16.4 4H7.6ZM17.25 5.5C17.5815 5.5 17.8995 5.6317 18.1339 5.86612C18.3683 6.10054 18.5 6.41848 18.5 6.75C18.5 7.08152 18.3683 7.39946 18.1339 7.63388C17.8995 7.8683 17.5815 8 17.25 8C16.9185 8 16.6005 7.8683 16.3661 7.63388C16.1317 7.39946 16 7.08152 16 6.75C16 6.41848 16.1317 6.10054 16.3661 5.86612C16.6005 5.6317 16.9185 5.5 17.25 5.5ZM12 7C13.3261 7 14.5979 7.52678 15.5355 8.46447C16.4732 9.40215 17 10.6739 17 12C17 13.3261 16.4732 14.5979 15.5355 15.5355C14.5979 16.4732 13.3261 17 12 17C10.6739 17 9.40215 16.4732 8.46447 15.5355C7.52678 14.5979 7 13.3261 7 12C7 10.6739 7.52678 9.40215 8.46447 8.46447C9.40215 7.52678 10.6739 7 12 7V7ZM12 9C11.2044 9 10.4413 9.31607 9.87868 9.87868C9.31607 10.4413 9 11.2044 9 12C9 12.7956 9.31607 13.5587 9.87868 14.1213C10.4413 14.6839 11.2044 15 12 15C12.7956 15 13.5587 14.6839 14.1213 14.1213C14.6839 13.5587 15 12.7956 15 12C15 11.2044 14.6839 10.4413 14.1213 9.87868C13.5587 9.31607 12.7956 9 12 9Z"></path>
                  </svg>
                </button>
                <div className="text-base text-[#35393B] font-bold text-ellipsis overflow-hidden">
                  <span>Instagram</span>
                </div>
              </a>
            </li>
            <li>
              <a
                href={data.facebook}
                target="_blank"
                className="px-6 py-2 flex items-center space-x-4 hover:bg-[#f9f7f7] transition-all ease-linear"
              >
                <button className="p-2 text-white bg-[#4a4a4a] rounded-full">
                  <svg
                    ariaHidden="true"
                    focusable="false"
                    className="h-6 w-6 text-inherit fill-current overflow-visible"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                  >
                    <path d="M22 12.0609C22 6.50383 17.5234 2 12 2C6.47656 2 2 6.50383 2 12.0609C2 17.0835 5.65625 21.2454 10.4375 22V14.9691H7.89844V12.0609H10.4375V9.84437C10.4375 7.32325 11.9297 5.93005 14.2148 5.93005C15.3086 5.93005 16.4531 6.12655 16.4531 6.12655V8.60248H15.1914C13.9492 8.60248 13.5625 9.37866 13.5625 10.1745V12.0609H16.3359L15.8926 14.9691H13.5625V22C18.3438 21.2454 22 17.0835 22 12.0609Z"></path>
                  </svg>
                </button>
                <div className="text-base text-[#35393B] font-bold text-ellipsis overflow-hidden">
                  <span>Facebook</span>
                </div>
              </a>
            </li>
            <li>
              <a
                href={data.telegram}
                target="_blank"
                className="px-6 py-2 flex items-center space-x-4 hover:bg-[#f9f7f7] transition-all ease-linear"
              >
                <button className="p-2 text-white bg-[#4a4a4a] rounded-full">
                  <svg
                    className="h-6 w-6 text-inherit fill-current overflow-visible"
                    ariaHidden="true"
                    focusable="false"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                  >
                    <path
                      fill="currentColor"
                      d="M446.7 98.6l-67.6 318.8c-5.1 22.5-18.4 28.1-37.3 17.5l-103-75.9-49.7 47.8c-5.5 5.5-10.1 10.1-20.7 10.1l7.4-104.9 190.9-172.5c8.3-7.4-1.8-11.5-12.9-4.1L117.8 284 16.2 252.2c-22.1-6.9-22.5-22.1 4.6-32.7L418.2 66.4c18.4-6.9 34.5 4.1 28.5 32.2z"
                    ></path>
                  </svg>
                </button>
                <div className="text-base text-[#35393B] font-bold text-ellipsis overflow-hidden">
                  <span>Telegram</span>
                </div>
              </a>
            </li>
            <li>
              <a
                href={"https://wa.me/" + data.whatsapp}
                target="_blank"
                className="px-6 py-2 flex items-center space-x-4 hover:bg-[#f9f7f7] transition-all ease-linear"
              >
                <button className="p-2 text-white bg-[#4a4a4a] rounded-full">
                  <svg
                    className="h-6 w-6 text-inherit fill-current overflow-visible"
                    ariaHidden="true"
                    focusable="false"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                  >
                    <path
                      fill="currentColor"
                      d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z"
                    ></path>
                  </svg>
                </button>
                <div className="text-base text-[#35393B] font-bold text-ellipsis overflow-hidden">
                  <span>Whatsapp</span>
                </div>
              </a>
            </li>
            <li>
              <a
                href={data.bookMeeting}
                target="_blank"
                className="px-6 py-2 flex items-center space-x-4 hover:bg-[#f9f7f7] transition-all ease-linear"
              >
                <button className="p-2 text-white bg-[#4a4a4a] rounded-full">
                  <svg
                    className="h-6 w-6 text-inherit fill-current overflow-visible"
                    ariaHidden="true"
                    focusable="false"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                  >
                    <path
                      fill="currentColor"
                      d="M424 61.12h-63.04v-8.56a12.4 12.4 0 10-24.72 0v8.56H172.4v-8a12.4 12.4 0 00-24.8 0v8H84.72a40 40 0 00-40 40V422a40 40 0 0040 40H424a40 40 0 0040-40V101.12a40 40 0 00-40-40zm14.56 361.44a16 16 0 01-16 16H85.28a16 16 0 01-16-16V100.8a16 16 0 0116-16h62.32v29.36a12.4 12.4 0 0024.8 0V84.8H336v29.04a12.4 12.4 0 0024.72 0V84.8h61.84a16 16 0 0116 16zM318.32 320.8a4.4 4.4 0 001.28-3.12 4.64 4.64 0 00-4.56-4.56 4.56 4.56 0 00-3.2 1.36 68.96 68.96 0 01-44 15.52 64.88 64.88 0 01-66.88-62.56 64.88 64.88 0 0166.88-62.64 69.52 69.52 0 0143.52 15.12 4.48 4.48 0 003.2 1.36 4.56 4.56 0 004.56-4.56 4.8 4.8 0 00-1.28-3.12 79.12 79.12 0 00-49.68-17.28c-42.08 0-76.16 32-76.16 71.36s34.08 71.36 76.16 71.36a78.4 78.4 0 0050.08-17.76z"
                    ></path>
                  </svg>
                </button>
                <div className="text-base text-[#35393B] font-bold text-ellipsis overflow-hidden">
                  <span>Book a meeting with me</span>
                </div>
              </a>
            </li>
          </ul>
        </main>
      </div>
    </div>
  );
}

export default Template2;
