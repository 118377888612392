// modules
import { createSlice } from "@reduxjs/toolkit";

const cardSlice = createSlice({
  name: "cards",
  initialState: [],
  reducers: {
    setCards: (state, action) => {
      const { payload } = action;
      return payload;
    },
    updateCard: (state, action) => {
      const { payload } = action;
      let tmpt = state.map((dt) => {
        if (dt._id === payload._id) {
          return payload;
        } else {
          return dt;
        }
      });

      return tmpt;
    },
  },
});

export const { setCards, updateCard } = cardSlice.actions;
export default cardSlice.reducer;
