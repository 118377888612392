import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";

// services
import authServices from "../../services/auth.service";

// components
import Dashboard from "../../components/Dashboard";

function Profile() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  const [formValues, setFormValues] = useState({
    company: "",
    license: "",
    firstName: "",
    lastName: "",
    username: "",
  });

  useEffect(() => {
    if (user) {
      setFormValues({
        company: user.company,
        license: user.license,
        firstName: user.firstName,
        lastName: user.lastName,
        username: user.username,
      });
    }
  }, [user]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleUpdate = async () => {
    try {
      const response = await dispatch(
        authServices.update(user._id, formValues)
      );
      if (response.success) {
        toast.success(response.status);
      } else {
        toast.success(response.status);
      }
    } catch (err) {
      console.log(err);
      let error = err.response.data.error || err;
      let status = err.response.data.status;
      toast.error(error.message || status, {});
    }
  };

  return (
    <Dashboard>
      <div className="mt-6">
        <h1 className="text-xl md:text-2xl text-gray-700 font-bold leading-4">
          Profile Settings
        </h1>

        <div className="my-6">
          <div className="px-4 py-8 rounded-lg bg-[#f9fafb]">
            <div className="grid grid-cols-6 gap-4 md:gap-6 lg:gap-8">
              <div className="col-span-6 md:col-span-3 flex flex-col space-y-2">
                <label
                  htmlFor="company"
                  className="text-[#111827] text-sm font-medium"
                >
                  Company
                </label>
                <input
                  className="profile_input-shadow w-full p-2 text-[#111827] text-sm border-2 border-[#d1d5db] rounded-md outline-none focus:border-[#0891b2] transition-all ease-linear duration-150"
                  id="company"
                  name="company"
                  type="text"
                  placeholder="Company"
                  value={formValues.company}
                  onChange={handleChange}
                />
              </div>
              <div className="col-span-6 md:col-span-3 flex flex-col space-y-2">
                <label
                  htmlFor="license"
                  className="text-[#111827] text-sm font-medium"
                >
                  License
                </label>
                <input
                  className="profile_input-shadow w-full p-2 text-[#111827] text-sm border-2 border-[#d1d5db] rounded-md outline-none focus:border-[#0891b2] transition-all ease-linear duration-150"
                  id="license"
                  name="license"
                  type="text"
                  placeholder="License"
                  value={formValues.license}
                  onChange={handleChange}
                />
              </div>
              <div className="col-span-6 md:col-span-3 flex flex-col space-y-2">
                <label
                  htmlFor="firstName"
                  className="text-[#111827] text-sm font-medium"
                >
                  First Name
                </label>
                <input
                  className="profile_input-shadow w-full p-2 text-[#111827] text-sm border-2 border-[#d1d5db] rounded-md outline-none focus:border-[#0891b2] transition-all ease-linear duration-150"
                  id="firstName"
                  name="firstName"
                  type="text"
                  placeholder="First Name"
                  value={formValues.firstName}
                  onChange={handleChange}
                />
              </div>
              <div className="col-span-6 md:col-span-3 flex flex-col space-y-2">
                <label
                  htmlFor="lastName"
                  className="text-[#111827] text-sm font-medium"
                >
                  Last Name
                </label>
                <input
                  className="profile_input-shadow w-full p-2 text-[#111827] text-sm border-2 border-[#d1d5db] rounded-md outline-none focus:border-[#0891b2] transition-all ease-linear duration-150"
                  id="lastName"
                  name="lastName"
                  type="text"
                  placeholder="Last Name"
                  value={formValues.lastName}
                  onChange={handleChange}
                />
              </div>
              <div className="col-span-6 md:col-span-3 flex flex-col space-y-2">
                <label
                  htmlFor="username"
                  className="text-[#111827] text-sm font-medium"
                >
                  Username
                </label>
                <input
                  className="profile_input-shadow w-full p-2 text-[#111827] text-sm border-2 border-[#d1d5db] rounded-md outline-none focus:border-[#0891b2] transition-all ease-linear duration-150"
                  id="username"
                  name="username"
                  type="text"
                  placeholder="Username"
                  value={formValues.username}
                  onChange={handleChange}
                />
              </div>
              <div className="col-span-6 md:col-span-3 flex flex-col space-y-2">
                <label
                  htmlFor="email"
                  className="text-[#111827] text-sm font-medium"
                >
                  E-mail
                </label>
                <input
                  disabled
                  className="profile_input-shadow w-full p-2 text-[#111827] text-sm border-2 border-[#d1d5db] rounded-md outline-none focus:border-[#0891b2] transition-all ease-linear duration-150"
                  id="email"
                  name="email"
                  type="email"
                  placeholder="E-mail"
                  value={user.email}
                />
              </div>
              <div className="col-span-6 flex flex-wrap gap-4">
                <button
                  className="px-4 py-2 text-white text-base font-normal rounded-lg bg-[#0e7490] hover:bg-[#199dbd] transition-all ease-linear duration-150 outline-none"
                  onClick={handleUpdate}
                >
                  Save All
                </button>
                <button className="px-4 py-2 text-white text-base font-normal rounded-lg bg-[#0e7490] hover:bg-[#199dbd] transition-all ease-linear duration-150 outline-none">
                  Change Password
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Dashboard>
  );
}

export default Profile;
