import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import ClassicTheme from "../../assets/themeBg/classic.png";
import CeramicWhiteTheme from "../../assets/themeBg/ceramic_white.png";

// services
import cardServices from "../../services/card.service";

function Template3() {
  const params = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [showFrontSide, setShowFrontSide] = useState(true);
  const [data, setData] = useState({
    theme: "",
    firstName: "",
    lastName: "",
    organization: "",
  });

  useEffect(() => {
    {
      (async () => {
        try {
          const response = await cardServices.getById(
            params.templateName,
            params.cardId
          );
          setIsLoading(false);
          if (response.success) {
            setData({
              theme: response.data.theme,
              firstName: response.data.firstName,
              lastName: response.data.lastName,
              organization: response.data.organization,
            });
          } else {
            toast.success(response.status);
          }
        } catch (err) {
          setIsLoading(false);
          console.log(err);
          let error = err.response.data.error || err;
          let status = err.response.data.status;
          toast.error(error.message || status, {});
        }
      })();
    }
  }, []);

  return (
    <div className="flex flex-col items-center justify-center">
      <div className="editor_card relative w-[275px] h-[160px] sm:w-[300px] sm:h-[175px] md:w-[400px] md:h-[235px] my-12 mx-auto">
        <div
          className="flip_inner box-shadow"
          style={!showFrontSide ? { transform: "rotateY(180deg)" } : {}}
        >
          <div
            className="flip_card absolute h-full w-full rounded-xl"
            style={{
              backgroundImage: `url(${
                data.theme === "ceramic_white"
                  ? CeramicWhiteTheme
                  : ClassicTheme
              })`,
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }}
          >
            <div className="absolute left-5 bottom-5 sm:left-7 sm:bottom-6 md:left-9 md:bottom-7 flex flex-col text-white">
              <span
                className={`${
                  data.theme === "classic"
                    ? "gradient-text"
                    : "gradient_gold-text"
                } text-inherit text-lg sm:text-2xl md:text-3xl font-bold`}
              >
                {" "}
                {data.firstName} {data.lastName}{" "}
              </span>
              <span
                className={`${
                  data.theme === "classic"
                    ? "gradient-text"
                    : "gradient_gold-text"
                } text-inherit text-xs sm:text-sm md:text-base font-semibold`}
              >
                {" "}
                {data.organization}{" "}
              </span>
            </div>
          </div>
          <div
            className="flip_card absolute h-full w-full rounded-xl"
            style={{
              transform: "rotateY(180deg)",
              backgroundImage: `url(${
                data.theme === "ceramic_white"
                  ? CeramicWhiteTheme
                  : ClassicTheme
              })`,
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }}
          >
            <div className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2">
              <img
                className="w-12 sm:w-14 md:w-16"
                src="/static/images/savage-sales-logo.png"
                alt="Savage Sales"
              />
            </div>
          </div>
        </div>
      </div>

      <button
        className="flex items-center space-x-2"
        onClick={() => setShowFrontSide((prev) => !prev)}
      >
        <span className="text-sm"> Flip </span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-6 h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
          />
        </svg>
      </button>
    </div>
  );
}

export default Template3;
