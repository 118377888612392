import React from "react";

function Loader() {
  return (
    <div className="fixed top-0 left-0 right-0 bottom-0 bg-black z-[190128]">
      <div className="w-full h-full flex flex-col justify-center items-center text-4xl md:text-7xl font-bold space-y-6">
        <span className="text-white">Loading</span>
        <span className="loader_icon" role="img">
          ⚡️
        </span>
      </div>
    </div>
  );
}

export default Loader;
