import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  FaFacebookF,
  FaTwitter,
  FaTelegramPlane,
  FaWhatsapp,
  FaInstagram,
} from "react-icons/fa";
import { FiPhoneCall } from "react-icons/fi";
import { BsArrowDownShort } from "react-icons/bs";

import coverPlaceholder from "../../assets/images/cover_placeholder.png";
import profilePlaceholder from "../../assets/images/profile_placeholder.png";
import organizationPlaceholder from "../../assets/images/logo_placeholder.png";

// services
import cardServices from "../../services/card.service";
import Loader from "../Loader";

function Template4() {
  const params = useParams();

  const [showAbout, setShowAbout] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState({
    dre: "",
    name: "",
    profession: "",
    email: "",
    phone: "",
    facebook: "",
    twitter: "",
    instagram: "",
    telegram: "",
    whatsapp: "",
    coverImage: "",
    profileImage: "",
    website: "",
    about: "",
  });

  useEffect(() => {
    {
      (async () => {
        try {
          const response = await cardServices.getById(
            params.templateName,
            params.cardId
          );
          setIsLoading(false);
          if (response.success) {
            setData({
              name: response.data.name,
              dre: response.data.dre,
              profession: response.data.profession,

              email: response.data.email,
              phone: response.data.phone,
              facebook: response.data.facebook,
              instagram: response.data.instagram,
              telegram: response.data.telegram,
              whatsapp: response.data.whatsapp,
              website: response.data.website,

              coverImage: response.data.coverImage,
              profileImage: response.data.profileImage,
              about: response.data.about,
            });
          } else {
            toast.success(response.status);
          }
        } catch (err) {
          setIsLoading(false);
          console.log(err);
          let error = err.response.data.error || err;
          let status = err.response.data.status;
          toast.error(error.message || status, {});
        }
      })();
    }
  }, []);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <div className="w-full xl:max-w-6xl px-2 xl:mx-auto">
        <div className="relative w-full">
          <div className="w-full overflow-hidden">
            <img
              className="w-full object-contain"
              src={data.coverImage || coverPlaceholder}
              alt="Cover Image"
            />
          </div>
          <div
            className="absolute top-[85%] left-1/2 transform -translate-x-1/2 w-32 h-32 md:w-44 md:h-44 rounded-full overflow-hidden bg-white"
            style={{
              boxShadow: "0px 2px 7px 0px #000000",
            }}
          >
            <img
              className="w-full h-full object-cover"
              src={data.profileImage || profilePlaceholder}
              alt="Profile Image"
            />
          </div>
        </div>
        <div className="mt-32">
          <h1 className="text-center text-xl md:text-4xl font-medium">
            {data.name}, {data.profession}
          </h1>
          <h2 className="mt-6 text-center text-xl md:text-4xl font-normal">
            DRE {data.dre}
          </h2>

          <div className="mt-10 flex items-center justify-center flex-wrap gap-4">
            <a
              className="p-3 text-2xl md:text-3xl text-white bg-[#2C6ECF] rounded-full hover:scale-105 transition-all duration-200 ease-in-out"
              href={data.facebook}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaFacebookF />
            </a>
            <a
              className="p-3 text-2xl md:text-3xl text-white bg-[#2C6ECF] rounded-full hover:scale-105 transition-all duration-200 ease-in-out"
              href={data.phone}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FiPhoneCall />
            </a>

            <a
              className="p-3 text-2xl md:text-3xl text-white bg-[#2C6ECF] rounded-full hover:scale-105 transition-all duration-200 ease-in-out"
              href={data.twitter}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaTwitter />
            </a>
            <a
              className="p-3 text-2xl md:text-3xl text-white bg-[#2C6ECF] rounded-full hover:scale-105 transition-all duration-200 ease-in-out"
              href={data.telegram}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaTelegramPlane />
            </a>
            <a
              className="p-3 text-2xl md:text-3xl text-white bg-[#2C6ECF] rounded-full hover:scale-105 transition-all duration-200 ease-in-out"
              href={data.whatsapp}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaWhatsapp />
            </a>
            <a
              className="p-3 text-2xl md:text-3xl text-white bg-[#2C6ECF] rounded-full hover:scale-105 transition-all duration-200 ease-in-out"
              href={data.instagram}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaInstagram />
            </a>
            <a
              className="p-3 text-2xl md:text-3xl text-white bg-[#2C6ECF] rounded-full hover:scale-105 transition-all duration-200 ease-in-out"
              href={data.website}
              target="_blank"
              rel="noopener noreferrer"
            >
              <svg
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6 text-inherit fill-current overflow-visible"
              >
                <path
                  xmlns="http://www.w3.org/2000/svg"
                  d="M13.5706 6.12903C12.9859
                                    2.52823 11.6069 0 10.0021 0C8.39724 0 7.0182 2.52823 6.43353
                                    6.12903H13.5706ZM6.13111 10C6.13111 10.8952 6.17949 11.754 6.26417
                                    12.5806H13.7359C13.8206 11.754 13.869 10.8952 13.869 10C13.869 9.10484
                                    13.8206 8.24597 13.7359 7.41935H6.26417C6.17949 8.24597 6.13111 9.10484
                                    6.13111 10ZM19.2239 6.12903C18.0706 3.39113 15.7359 1.27419 12.8529
                                    0.419355C13.8368 1.78226 14.5142 3.83468 14.869 6.12903H19.2239ZM7.14724
                                    0.419355C4.2682 1.27419 1.92949 3.39113 0.780301 6.12903H5.13514C5.48595
                                    3.83468 6.16337 1.78226 7.14724 0.419355ZM19.6553 7.41935H15.0303C15.115
                                    8.26613 15.1634 9.13306 15.1634 10C15.1634 10.8669 15.115 11.7339 15.0303
                                    12.5806H19.6513C19.873 11.754 19.998 10.8952 19.998 10C19.998 9.10484 19.873
                                    8.24597 19.6553 7.41935ZM4.84079 10C4.84079 9.13306 4.88917 8.26613 4.97385
                                    7.41935H0.348849C0.131107 8.24597 0.0020752 9.10484 0.0020752 10C0.0020752
                                    10.8952 0.131107 11.754 0.348849 12.5806H4.96982C4.88917 11.7339 4.84079 10.8669
                                    4.84079 10ZM6.43353 13.871C7.0182 17.4718 8.39724 20 10.0021 20C11.6069 20 12.9859
                                    17.4718 13.5706 13.871H6.43353ZM12.8569 19.5806C15.7359 18.7258 18.0747 16.6089
                                    19.2279 13.871H14.873C14.5182 16.1653 13.8408 18.2177 12.8569 19.5806ZM0.780301
                                    13.871C1.93353 16.6089 4.2682 18.7258 7.15127 19.5806C6.1674 18.2177 5.48998
                                    16.1653 5.13514 13.871H0.780301Z"
                  fill="#FFFFFF"
                ></path>
              </svg>
            </a>
          </div>

          <div
            className="mt-20 mx-3 md:mx-auto max-w-xl px-2 py-3 border-t-[1px] border-l-[1px] border-r-[1px] border-b-2 border-[#2C6ECF] overflow-hidden"
            style={{
              boxShadow: "0px 2px 7px 0px #000000",
            }}
          >
            <div
              className="text-sm text-[#2C6ECF] font-medium cursor-pointer"
              onClick={() => setShowAbout((prev) => !prev)}
            >
              <span className="flex items-center space-x-2">
                {" "}
                <BsArrowDownShort className="text-lg text-[#F19737]" /> About me
              </span>
            </div>
            <p
              className="max-h-0 text-sm font-normal transition-all duration-500 ease-in-out overflow-hidden whitespace-pre-wrap"
              style={showAbout ? { maxHeight: "100vh", marginTop: "8px" } : {}}
            >
              {" "}
              {data.about}{" "}
            </p>
          </div>

          <div className="mt-16">
            <h2 className="text-2xl text-center md:text-4xl font-medium">
              Reach Me At:
            </h2>
            <div className="mt-2 w-28 md:w-44 mx-auto border-b-[1px] border-[#2C6ECF]"></div>
            <div className="mt-10 max-w-sm mx-auto space-y-4">
              <div className="w-full">
                <input
                  type="text"
                  name=""
                  id=""
                  className="w-full px-2 py-1 border-[1px] border-[#818a91] outline-none"
                  placeholder="Name"
                />
              </div>
              <div className="w-full">
                <input
                  type="text"
                  name=""
                  id=""
                  className="w-full px-2 py-1 border-[1px] border-[#818a91] outline-none"
                  placeholder="Phone"
                />
              </div>
              <div className="w-full">
                <input
                  type="email"
                  name=""
                  id=""
                  className="w-full px-2 py-1 border-[1px] border-[#818a91] outline-none"
                  placeholder="Email"
                />
              </div>
              <div className="w-full">
                <textarea
                  className="w-full px-2 py-1 border-[1px] border-[#818a91] outline-none"
                  name=""
                  id=""
                  placeholder="Message"
                  rows="6"
                ></textarea>
              </div>
              <div className="w-full">
                <button className="w-full px-2 py-2 text-white text-sm text-center font-medium bg-[#F19737]">
                  Send
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer className="mt-24 mb-8">
        <div className="w-40 mx-auto border-t-[1px] border-black"></div>
        <p className="mt-2 text-black text-center font-bold">
          {" "}
          Powered by{" "}
          <a
            className="underline"
            href="http://savagesales.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            Savage Sales
          </a>{" "}
        </p>
      </footer>
    </>
  );
}

export default Template4;
