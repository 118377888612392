import React from "react";
import { useParams } from "react-router-dom";

// components
import Dashboard from "../../components/Dashboard";
import Template1Editor from "../../components/Editor/Template1.Editor";
import Template2Editor from "../../components/Editor/Template2.Editor";
import Template3Editor from "../../components/Editor/Template3.Editor";
import Template4Editor from "../../components/Editor/Template4.Editor";
import Template5Editor from "../../components/Editor/Template5.Editor";

function Edit() {
  const params = useParams();

  if (params.templateName === "Template-1") {
    return (
      <Dashboard>
        <Template1Editor />
      </Dashboard>
    );
  } else if (params.templateName === "Template-2") {
    return (
      <Dashboard>
        <Template2Editor />
      </Dashboard>
    );
  } else if (params.templateName === "Template-3") {
    return (
      <Dashboard>
        <Template3Editor />
      </Dashboard>
    );
  } else if (params.templateName === "Template-4") {
    return (
      <Dashboard>
        <Template4Editor />
      </Dashboard>
    );
  } else if (params.templateName === "Template-5") {
    return (
      <Dashboard>
        <Template5Editor />
      </Dashboard>
    );
  }
}

export default Edit;
