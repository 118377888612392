// modules
import { configureStore } from "@reduxjs/toolkit";
import logger from "redux-logger";

// reducer
import userReducer from "./reducers/userSlice";
import cardReducer from "./reducers/cardSlice";

export default configureStore({
  reducer: {
    user: userReducer,
    cards: cardReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
});
