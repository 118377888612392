import React, { useEffect, useReducer, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";

import coverPlaceholder from "../../assets/images/cover_placeholder.png";
import profilePlaceholder from "../../assets/images/profile_placeholder.png";
import organizationPlaceholder from "../../assets/images/logo_placeholder.png";

// services
import cardServices from "../../services/card.service";

function Template2Editor() {
  const dispatch = useDispatch();
  const params = useParams();
  const cards = useSelector((state) => state.cards);

  const [data, setData] = useState({
    _id: "",
    name: "",
    dre: "",
    profession: "",
    organization: "",
    email: "",
    phone: "",
    facebook: "",
    instagram: "",
    telegram: "",
    whatsapp: "",
    website: "",
    coverImage: "",
    profileImage: "",
    about: "",
  });

  useEffect(() => {
    const retrivedData = cards.filter((dt) => dt._id === params.cardId)[0];
    console.log(retrivedData);
    if (retrivedData) {
      setData({
        _id: retrivedData._id,
        dre: retrivedData.dre,
        name: retrivedData.name,
        profession: retrivedData.profession,

        email: retrivedData.email,
        phone: retrivedData.phone,

        facebook: retrivedData.facebook,
        instagram: retrivedData.instagram,
        telegram: retrivedData.telegram,
        whatsapp: retrivedData.whatsapp,
        website: retrivedData.website,

        coverImage: retrivedData.coverImage,
        profileImage: retrivedData.profileImage,
        about: retrivedData.about,
      });
    }
  }, [cards]);

  const handleChange = async (e) => {
    const { name, value, files } = e.target;

    if (
      name === "profileImage" ||
      name === "organizationImage" ||
      name === "coverImage"
    ) {
      const base64 = await convertBase64(files[0]);
      setData((prev) => ({ ...prev, [name]: base64 }));
    } else {
      setData((prev) => ({ ...prev, [name]: value }));
    }
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleUpdate = async () => {
    try {
      const response = await dispatch(
        cardServices.update(params.templateName, data._id, {
          ...data,
        })
      );
      if (response.success) {
        toast.success(response.status);
      } else {
        toast.success(response.status);
      }
    } catch (err) {
      console.log(err);
      let error = err.response.data.error || err;
      let status = err.response.data.status;
      toast.error(error.message || status, {});
    }
  };

  return (
    <div className="mt-6 flex flex-col items-center justify-center">
      <div className="w-full flex justify-end space-x-3">
        <a
          href={`/view/${params.templateName}/${params.cardId}`}
          target="_blank"
          className="relative inline-flex items-center justify-center p-0.5 mb-2 mr-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-red-200 via-red-300 to-yellow-200 group-hover:from-red-200 group-hover:via-red-300 group-hover:to-yellow-200 dark:text-white dark:hover:text-gray-900 focus:ring-4 focus:outline-none focus:ring-red-100 dark:focus:ring-red-400"
        >
          <span className="relative px-5 py-2.5 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
            View
          </span>
        </a>
        <button
          className="relative inline-flex items-center justify-center p-0.5 mb-2 mr-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-red-200 via-red-300 to-yellow-200 group-hover:from-red-200 group-hover:via-red-300 group-hover:to-yellow-200 dark:text-white dark:hover:text-gray-900 focus:ring-4 focus:outline-none focus:ring-red-100 dark:focus:ring-red-400"
          onClick={handleUpdate}
        >
          <span className="relative px-5 py-2.5 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
            Save
          </span>
        </button>
      </div>
      <div className="w-full xl:max-w-6xl px-2 xl:mx-auto">
        {/* <div className="relative w-full">
          <div className="w-full overflow-hidden">
            <img
              className="w-full object-contain"
              src={data.coverImage || coverPlaceholder}
              alt="Cover Image"
            />
          </div>
          <div
            className="absolute top-[85%] left-1/2 transform -translate-x-1/2 w-32 h-32 md:w-44 md:h-44 rounded-full overflow-hidden"
            style={{
              boxShadow: "0px 2px 7px 0px #000000",
            }}
          >
            <img
              className="w-full h-full object-cover"
              src={data.profileImage || profilePlaceholder}
              alt="Profile Image"
            />
          </div>
        </div> */}

        <header className="relative w-full">
          <label
            htmlFor="coverImage"
            className="reveal_edit-btn relative block w-full cursor-pointer after:content-[''] hover:after:absolute after:top-0 after:left-0 after:right-0 after:bottom-0 after:bg-black after:opacity-10 transition-all duration-200 ease-in-out"
          >
            <img
              className="w-full object-contain"
              alt={data.name}
              src={data.coverImage || coverPlaceholder}
            />
            <span className="edit-btn absolute right-2 top-2 z-50 p-2 rounded-full bg-black opacity-0 transition-all duration-200 ease-in-out">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className=" w-4 h-4 text-white"
              >
                <path d="M21.731 2.269a2.625 2.625 0 00-3.712 0l-1.157 1.157 3.712 3.712 1.157-1.157a2.625 2.625 0 000-3.712zM19.513 8.199l-3.712-3.712-12.15 12.15a5.25 5.25 0 00-1.32 2.214l-.8 2.685a.75.75 0 00.933.933l2.685-.8a5.25 5.25 0 002.214-1.32L19.513 8.2z" />
              </svg>
            </span>

            <input
              hidden
              className=""
              id="coverImage"
              name="coverImage"
              type="file"
              onChange={handleChange}
            />
          </label>

          <div
            className="absolute top-[85%] left-1/2 transform -translate-x-1/2 w-32 h-32 md:w-44 md:h-44 rounded-full overflow-hidden bg-white"
            style={{
              boxShadow: "0px 2px 7px 0px #000000",
            }}
          >
            <label
              htmlFor="profileImage"
              className="reveal_edit-btn relative block h-full w-full cursor-pointer after:content-[''] hover:after:absolute after:top-0 after:left-0 after:right-0 after:bottom-0 after:bg-black after:opacity-10 transition-all duration-200 ease-in-out"
            >
              <span className="block p-2 h-full w-full overflow-hidden">
                <img
                  className="h-full w-full object-contain"
                  src={data.profileImage || profilePlaceholder}
                  alt={data.name}
                />
              </span>
              <span className="edit-btn absolute left-1/2 top-[40%] transform -translate-x-1/2 -translate-y-1/2 z-50 p-2 rounded-full bg-[#00000080] opacity-0 transition-all duration-200 ease-in-out">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className=" w-4 h-4 text-white"
                >
                  <path d="M21.731 2.269a2.625 2.625 0 00-3.712 0l-1.157 1.157 3.712 3.712 1.157-1.157a2.625 2.625 0 000-3.712zM19.513 8.199l-3.712-3.712-12.15 12.15a5.25 5.25 0 00-1.32 2.214l-.8 2.685a.75.75 0 00.933.933l2.685-.8a5.25 5.25 0 002.214-1.32L19.513 8.2z" />
                </svg>
              </span>

              <input
                hidden
                className=""
                id="profileImage"
                name="profileImage"
                type="file"
                onChange={handleChange}
              />
            </label>
          </div>
        </header>
        <main className="mt-24">
          {/* first part of body*/}
          <div className="mx-6">
            <div className="w-full">
              <input
                className="w-full text-inherit text-3xl text-[#35393b] font-bold border-2 border-dotted border-transparent hover:border-black focus:border-black outline-none"
                name="name"
                type="text"
                placeholder="Your name..."
                value={data.name}
                onChange={handleChange}
              />
            </div>

            <div className="mt-2 w-full">
              <input
                className="w-full italic text-inherit text-2xl text-[#35393b] font-medium border-2 border-dotted border-transparent hover:border-black focus:border-black outline-none"
                name="profession"
                type="text"
                placeholder="Your profession..."
                onChange={handleChange}
                value={data.profession}
              />
            </div>
            <div className="mt-2 w-full flex items-center">
              <span className="text-inherit text-2xl">DRE #</span>
              <input
                className="w-full flex-1 italic text-inherit text-2xl text-[#35393b] font-medium border-2 border-dotted border-transparent hover:border-black focus:border-black outline-none"
                name="dre"
                type="text"
                placeholder="DRE"
                onChange={handleChange}
                value={data.dre}
              />
            </div>

            <div className="mt-5 w-full">
              <textarea
                className="w-full italic text-inherit text-lg text-[#35393b] font-normal border-2 border-dotted border-transparent hover:border-black focus:border-black outline-none"
                name="about"
                type="text"
                placeholder="Write something about yourself"
                onChange={handleChange}
                rows="5"
                value={data.about}
              ></textarea>
            </div>
          </div>

          {/* secord part of body - buttons */}
          <ul className="mt-6 mb-3 flex flex-col">
            <li>
              <div className="px-6 pt-3 pb-2 flex items-center space-x-4 hover:bg-[#f9f7f7] transition-all ease-linear">
                <button className="p-2 text-white bg-[#2C6ECF] rounded-full">
                  <svg
                    ariaHidden="true"
                    focusable="false"
                    className="h-6 w-6 text-inherit fill-current overflow-visible"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                  >
                    <path d="M20 4H4C2.9 4 2.01 4.9 2.01 6L2 18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V6C22 4.9 21.1 4 20 4ZM19.6 8.25L12.53 12.67C12.21 12.87 11.79 12.87 11.47 12.67L4.4 8.25C4.15 8.09 4 7.82 4 7.53C4 6.86 4.73 6.46 5.3 6.81L12 11L18.7 6.81C19.27 6.46 20 6.86 20 7.53C20 7.82 19.85 8.09 19.6 8.25Z"></path>
                  </svg>
                </button>
                <div className="w-full">
                  <input
                    className="w-full italic text-inherit text-xl text-[#1A202C] font-light border-2 border-dotted border-transparent hover:border-black focus:border-black outline-none"
                    name="email"
                    type="email"
                    placeholder="Your E-mail address"
                    onChange={handleChange}
                    value={data.email}
                  />
                </div>
              </div>
            </li>
            <li>
              <div className="px-6 py-2 flex items-center space-x-4 hover:bg-[#f9f7f7] transition-all ease-linear">
                <button className="p-2 text-white bg-[#2C6ECF] rounded-full">
                  <svg
                    ariaHidden="true"
                    focusable="false"
                    className="h-6 w-6 text-inherit fill-current overflow-visible"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                  >
                    <path d="M19.23 15.26L16.69 14.97C16.08 14.9 15.48 15.11 15.05 15.54L13.21 17.38C10.38 15.94 8.06004 13.63 6.62004 10.79L8.47004 8.94001C8.90004 8.51001 9.11004 7.91001 9.04004 7.30001L8.75004 4.78001C8.63004 3.77001 7.78004 3.01001 6.76004 3.01001H5.03004C3.90004 3.01001 2.96004 3.95001 3.03004 5.08001C3.56004 13.62 10.39 20.44 18.92 20.97C20.05 21.04 20.99 20.1 20.99 18.97V17.24C21 16.23 20.24 15.38 19.23 15.26V15.26Z"></path>
                  </svg>
                </button>
                <div className="w-full">
                  <input
                    className="w-full italic text-inherit text-xl text-[#1A202C] font-light border-2 border-dotted border-transparent hover:border-black focus:border-black outline-none"
                    name="phone"
                    type="tel"
                    placeholder="Your phone number"
                    onChange={handleChange}
                    value={data.phone}
                  />
                </div>
              </div>
            </li>
            <li>
              <div className="px-6 pt-2 pb-3 flex items-center space-x-4 hover:bg-[#f9f7f7] transition-all ease-linear">
                <button className="p-2 text-white bg-[#2C6ECF] rounded-full">
                  <svg
                    ariaHidden="true"
                    focusable="false"
                    className="h-6 w-6 text-inherit fill-current overflow-visible"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                  >
                    <path d="M7.8 2H16.2C19.4 2 22 4.6 22 7.8V16.2C22 17.7383 21.3889 19.2135 20.3012 20.3012C19.2135 21.3889 17.7383 22 16.2 22H7.8C4.6 22 2 19.4 2 16.2V7.8C2 6.26174 2.61107 4.78649 3.69878 3.69878C4.78649 2.61107 6.26174 2 7.8 2V2ZM7.6 4C6.64522 4 5.72955 4.37928 5.05442 5.05442C4.37928 5.72955 4 6.64522 4 7.6V16.4C4 18.39 5.61 20 7.6 20H16.4C17.3548 20 18.2705 19.6207 18.9456 18.9456C19.6207 18.2705 20 17.3548 20 16.4V7.6C20 5.61 18.39 4 16.4 4H7.6ZM17.25 5.5C17.5815 5.5 17.8995 5.6317 18.1339 5.86612C18.3683 6.10054 18.5 6.41848 18.5 6.75C18.5 7.08152 18.3683 7.39946 18.1339 7.63388C17.8995 7.8683 17.5815 8 17.25 8C16.9185 8 16.6005 7.8683 16.3661 7.63388C16.1317 7.39946 16 7.08152 16 6.75C16 6.41848 16.1317 6.10054 16.3661 5.86612C16.6005 5.6317 16.9185 5.5 17.25 5.5ZM12 7C13.3261 7 14.5979 7.52678 15.5355 8.46447C16.4732 9.40215 17 10.6739 17 12C17 13.3261 16.4732 14.5979 15.5355 15.5355C14.5979 16.4732 13.3261 17 12 17C10.6739 17 9.40215 16.4732 8.46447 15.5355C7.52678 14.5979 7 13.3261 7 12C7 10.6739 7.52678 9.40215 8.46447 8.46447C9.40215 7.52678 10.6739 7 12 7V7ZM12 9C11.2044 9 10.4413 9.31607 9.87868 9.87868C9.31607 10.4413 9 11.2044 9 12C9 12.7956 9.31607 13.5587 9.87868 14.1213C10.4413 14.6839 11.2044 15 12 15C12.7956 15 13.5587 14.6839 14.1213 14.1213C14.6839 13.5587 15 12.7956 15 12C15 11.2044 14.6839 10.4413 14.1213 9.87868C13.5587 9.31607 12.7956 9 12 9Z"></path>
                  </svg>
                </button>
                <div className="w-full">
                  <input
                    className="w-full italic text-inherit text-xl text-[#1A202C] font-light border-2 border-dotted border-transparent hover:border-black focus:border-black outline-none"
                    name="instagram"
                    type="text"
                    placeholder="Your instagram link"
                    onChange={handleChange}
                    value={data.instagram}
                  />
                </div>
              </div>
            </li>
            <li>
              <div className="px-6 py-2 flex items-center space-x-4 hover:bg-[#f9f7f7] transition-all ease-linear">
                <button className="p-2 text-white bg-[#2C6ECF] rounded-full">
                  <svg
                    ariaHidden="true"
                    focusable="false"
                    className="h-6 w-6 text-inherit fill-current overflow-visible"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                  >
                    <path d="M22 12.0609C22 6.50383 17.5234 2 12 2C6.47656 2 2 6.50383 2 12.0609C2 17.0835 5.65625 21.2454 10.4375 22V14.9691H7.89844V12.0609H10.4375V9.84437C10.4375 7.32325 11.9297 5.93005 14.2148 5.93005C15.3086 5.93005 16.4531 6.12655 16.4531 6.12655V8.60248H15.1914C13.9492 8.60248 13.5625 9.37866 13.5625 10.1745V12.0609H16.3359L15.8926 14.9691H13.5625V22C18.3438 21.2454 22 17.0835 22 12.0609Z"></path>
                  </svg>
                </button>
                <div className="w-full">
                  <input
                    className="w-full italic text-inherit text-xl text-[#1A202C] font-light border-2 border-dotted border-transparent hover:border-black focus:border-black outline-none"
                    name="facebook"
                    type="text"
                    placeholder="Your facebook link"
                    onChange={handleChange}
                    value={data.facebook}
                  />
                </div>
              </div>
            </li>
            <li>
              <div className="px-6 py-2 flex items-center space-x-4 hover:bg-[#f9f7f7] transition-all ease-linear">
                <button className="p-2 text-white bg-[#2C6ECF] rounded-full">
                  <svg
                    className="h-6 w-6 text-inherit fill-current overflow-visible"
                    ariaHidden="true"
                    focusable="false"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                  >
                    <path
                      fill="currentColor"
                      d="M446.7 98.6l-67.6 318.8c-5.1 22.5-18.4 28.1-37.3 17.5l-103-75.9-49.7 47.8c-5.5 5.5-10.1 10.1-20.7 10.1l7.4-104.9 190.9-172.5c8.3-7.4-1.8-11.5-12.9-4.1L117.8 284 16.2 252.2c-22.1-6.9-22.5-22.1 4.6-32.7L418.2 66.4c18.4-6.9 34.5 4.1 28.5 32.2z"
                    ></path>
                  </svg>
                </button>
                <div className="w-full">
                  <input
                    className="w-full italic text-inherit text-xl text-[#1A202C] font-light border-2 border-dotted border-transparent hover:border-black focus:border-black outline-none"
                    name="telegram"
                    type="text"
                    placeholder="Your telegram link"
                    onChange={handleChange}
                    value={data.telegram}
                  />
                </div>
              </div>
            </li>
            <li>
              <div className="px-6 py-2 flex items-center space-x-4 hover:bg-[#f9f7f7] transition-all ease-linear">
                <button className="p-2 text-white bg-[#2C6ECF] rounded-full">
                  <svg
                    className="h-6 w-6 text-inherit fill-current overflow-visible"
                    ariaHidden="true"
                    focusable="false"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                  >
                    <path
                      fill="currentColor"
                      d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z"
                    ></path>
                  </svg>
                </button>
                <div className="w-full">
                  <input
                    className="w-full italic text-inherit text-xl text-[#1A202C] font-light border-2 border-dotted border-transparent hover:border-black focus:border-black outline-none"
                    name="whatsapp"
                    type="text"
                    placeholder="Your whatsapp number"
                    onChange={handleChange}
                    value={data.whatsapp}
                  />
                </div>
              </div>
            </li>
            <li>
              <div className="px-6 py-2 flex items-center space-x-4 hover:bg-[#f9f7f7] transition-all ease-linear">
                <button className="p-2 text-white bg-[#2C6ECF] rounded-full">
                  <svg
                    viewBox="0 0 22 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6 text-inherit fill-current overflow-visible"
                  >
                    <path
                      xmlns="http://www.w3.org/2000/svg"
                      d="M13.5706 6.12903C12.9859
                                    2.52823 11.6069 0 10.0021 0C8.39724 0 7.0182 2.52823 6.43353
                                    6.12903H13.5706ZM6.13111 10C6.13111 10.8952 6.17949 11.754 6.26417
                                    12.5806H13.7359C13.8206 11.754 13.869 10.8952 13.869 10C13.869 9.10484
                                    13.8206 8.24597 13.7359 7.41935H6.26417C6.17949 8.24597 6.13111 9.10484
                                    6.13111 10ZM19.2239 6.12903C18.0706 3.39113 15.7359 1.27419 12.8529
                                    0.419355C13.8368 1.78226 14.5142 3.83468 14.869 6.12903H19.2239ZM7.14724
                                    0.419355C4.2682 1.27419 1.92949 3.39113 0.780301 6.12903H5.13514C5.48595
                                    3.83468 6.16337 1.78226 7.14724 0.419355ZM19.6553 7.41935H15.0303C15.115
                                    8.26613 15.1634 9.13306 15.1634 10C15.1634 10.8669 15.115 11.7339 15.0303
                                    12.5806H19.6513C19.873 11.754 19.998 10.8952 19.998 10C19.998 9.10484 19.873
                                    8.24597 19.6553 7.41935ZM4.84079 10C4.84079 9.13306 4.88917 8.26613 4.97385
                                    7.41935H0.348849C0.131107 8.24597 0.0020752 9.10484 0.0020752 10C0.0020752
                                    10.8952 0.131107 11.754 0.348849 12.5806H4.96982C4.88917 11.7339 4.84079 10.8669
                                    4.84079 10ZM6.43353 13.871C7.0182 17.4718 8.39724 20 10.0021 20C11.6069 20 12.9859
                                    17.4718 13.5706 13.871H6.43353ZM12.8569 19.5806C15.7359 18.7258 18.0747 16.6089
                                    19.2279 13.871H14.873C14.5182 16.1653 13.8408 18.2177 12.8569 19.5806ZM0.780301
                                    13.871C1.93353 16.6089 4.2682 18.7258 7.15127 19.5806C6.1674 18.2177 5.48998
                                    16.1653 5.13514 13.871H0.780301Z"
                      fill="#FFFFFF"
                    ></path>
                  </svg>
                </button>
                <div className="w-full">
                  <input
                    className="w-full italic text-inherit text-xl text-[#1A202C] font-light border-2 border-dotted border-transparent hover:border-black focus:border-black outline-none"
                    name="website"
                    type="text"
                    placeholder="Your website link"
                    onChange={handleChange}
                    value={data.website}
                  />
                </div>
              </div>
            </li>
          </ul>
        </main>
      </div>
    </div>
  );
}

export default Template2Editor;
