// modules
import axios from "../utils/axios";

import { updateCard } from "../utils/reducers/cardSlice";

const cardServices = {
  getById: (templateName, cardId) => {
    return axios({
      url: "/api/cards/" + templateName + "/" + cardId,
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    }).then((response) => {
      const resData = response.data;

      if (resData.success) {
        return {
          success: true,
          status: resData.status,
          data: resData.data,
        };
      } else {
        return {
          success: false,
          status: resData.status,
        };
      }
    });
  },
  update: (templateName, cardId, data) => (dispatch) => {
    return axios({
      url: "/api/cards/" + templateName + "/update/" + cardId,
      method: "put",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    }).then((response) => {
      const resData = response.data;

      if (resData.success) {
        dispatch(updateCard(resData.data));
        return {
          success: true,
          status: resData.status,
        };
      } else {
        return {
          success: false,
          status: resData.status,
        };
      }
    });
  },
};

export default cardServices;
