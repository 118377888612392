import React from "react";

// components
import Navbar from "../layouts/Navbar";

function Dashboard({ children }) {
  return (
    <div className="p-4 xl:ml-80">
      <Navbar />
      {children}
    </div>
  );
}

export default Dashboard;
