import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";

// services
import cardServices from "../../services/card.service";

// components
import Dashboard from "../Dashboard";

function Template1Editor() {
  const dispatch = useDispatch();
  const params = useParams();
  const cards = useSelector((state) => state.cards);

  const [data, setData] = useState({
    _id: "",
    name: "",
    profession: "",
    organization: "",
    email: "",
    phone: "",
    linkedin: "",
    facebook: "",
    instagram: "",
    profileImage: "",
  });

  useEffect(() => {
    const retrivedData = cards.filter((dt) => dt._id === params.cardId)[0];
    if (retrivedData) {
      setData({
        _id: retrivedData._id,
        name: retrivedData.name,
        profession: retrivedData.profession,
        organization: retrivedData.organization,
        email: retrivedData.email,
        phone: retrivedData.phone,
        linkedin: retrivedData.linkedin,
        facebook: retrivedData.facebook,
        instagram: retrivedData.instagram,
        profileImage: retrivedData.profileImage,
      });
    }
  }, [cards]);

  const handleChange = async (e) => {
    const { name, value, files } = e.target;

    if (name === "profileImage") {
      const base64 = await convertBase64(files[0]);
      setData((prev) => ({ ...prev, [name]: base64 }));
    } else {
      setData((prev) => ({ ...prev, [name]: value }));
    }
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleUpdate = async () => {
    try {
      const response = await dispatch(
        cardServices.update(params.templateName, data._id, data)
      );
      if (response.success) {
        toast.success(response.status);
      } else {
        toast.success(response.status);
      }
    } catch (err) {
      console.log(err);
      let error = err.response.data.error || err;
      let status = err.response.data.status;
      toast.error(error.message || status, {});
    }
  };

  return (
    <div className="mt-6 flex flex-col items-center justify-center">
      <div className="w-full flex justify-end space-x-3">
        <a
          href={`/view/${params.templateName}/${params.cardId}`}
          target="_blank"
          className="relative inline-flex items-center justify-center p-0.5 mb-2 mr-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-red-200 via-red-300 to-yellow-200 group-hover:from-red-200 group-hover:via-red-300 group-hover:to-yellow-200 dark:text-white dark:hover:text-gray-900 focus:ring-4 focus:outline-none focus:ring-red-100 dark:focus:ring-red-400"
        >
          <span className="relative px-5 py-2.5 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
            View
          </span>
        </a>
        <button
          className="relative inline-flex items-center justify-center p-0.5 mb-2 mr-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-red-200 via-red-300 to-yellow-200 group-hover:from-red-200 group-hover:via-red-300 group-hover:to-yellow-200 dark:text-white dark:hover:text-gray-900 focus:ring-4 focus:outline-none focus:ring-red-100 dark:focus:ring-red-400"
          onClick={handleUpdate}
        >
          <span className="relative px-5 py-2.5 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
            Save
          </span>
        </button>
      </div>
      <div className="editor_card box-shadow max-w-[440px] w-full my-12 bg-white rounded-xl overflow-hidden">
        <header className="relative w-full">
          <div className="relative min-h-[440px] h-full w-full">
            <div className="absolute top-0 left-0 right-0 bottom-0 overflow-hidden">
              <img
                className="w-full h-full object-contain"
                alt={data.name}
                src={data.profileImage}
              />
            </div>
            <label
              htmlFor="profileImage"
              className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 p-8 border-2 border-dotted border-transparent hover:border-black focus:border-black outline-none cursor-pointer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-12 h-12"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M2.25 15.75l5.159-5.159a2.25 2.25 0 013.182 0l5.159 5.159m-1.5-1.5l1.409-1.409a2.25 2.25 0 013.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 001.5-1.5V6a1.5 1.5 0 00-1.5-1.5H3.75A1.5 1.5 0 002.25 6v12a1.5 1.5 0 001.5 1.5zm10.5-11.25h.008v.008h-.008V8.25zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z"
                />
              </svg>
            </label>
            <input
              hidden
              className=""
              id="profileImage"
              name="profileImage"
              type="file"
              onChange={handleChange}
            />
          </div>

          <div className="absolute w-[calc(100% + 4px)] bottom-[-2px] left-0 right-0">
            <svg
              className="w-full"
              preserveAspectRatio="xMinYMax meet"
              viewBox="0 0 246 57"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M 214.7168,6.1113281 C 195.65271,5.9023124 172.37742,11.948182 137.87305,32.529297 110.16613,49.05604 86.980345,56.862784 65.015625,57 H 65 v 1 H 246 V 11.453125 C 236.0775,8.6129313 226.15525,6.2367376 214.7168,6.1113281 Z"
                fill="#ffffff"
                clipRule="evenodd"
                fillRule="evenodd"
              ></path>
              <path
                d="M 0,35.773438 V 58 H 65 L 64.97852,57 C 43.192081,57.127508 22.605139,49.707997 0,35.773438 Z "
                fill="#ffffff"
                clip-rule="evenodd"
                fill-rule="evenodd"
              ></path>
              <path
                fill="#4A4A4A"
                clipRule="evenodd"
                fillRule="evenodd"
                d="m 0,16.7221 v 19.052 C 45.4067,63.7643 82.6667,65.4583 137.873,32.5286 193.08,-0.401184 219.54,3.87965 246,11.4535 V 6.51403 C 185.24,-16.8661 135.913,29.331 97.6933,40.8564 59.4733,52.3818 33.6467,44.1494 0,16.7221 Z "
              ></path>
            </svg>
            <div className="absolute w-6 top-[35%] left-[86%]">
              <img
                className="w-full object-contain"
                src="/static/images/savage-sales-logo.png"
                alt="Savage Sales Logo"
              />
            </div>
          </div>
        </header>
        <main className="mt-6">
          {/* first part of body*/}
          <div className="mx-6 pl-4 border-l-2 border-dotted border-black space-y-1">
            <div className="w-full">
              <input
                className="w-full text-inherit text-3xl text-[#1A202C] font-extrabold border-2 border-dotted border-transparent hover:border-black focus:border-black outline-none"
                name="name"
                type="text"
                placeholder="Your name..."
                value={data.name}
                onChange={handleChange}
              />
            </div>

            <div className="w-full">
              <textarea
                className="w-full text-inherit text-xl text-[#1A202C] font-semibold border-2 border-dotted border-transparent hover:border-black focus:border-black outline-none"
                name="profession"
                type="text"
                placeholder="Your profession..."
                onChange={handleChange}
                rows="2"
                value={data.profession}
              ></textarea>
            </div>

            <div className="w-full">
              <input
                className="w-full italic text-inherit text-xl text-[#1A202C] font-light border-2 border-dotted border-transparent hover:border-black focus:border-black outline-none"
                name="organization"
                type="text"
                placeholder="Your organization name..."
                onChange={handleChange}
                value={data.organization}
              />
            </div>
          </div>

          {/* secord part of body - buttons */}
          <ul className="mt-10 flex flex-col">
            <li>
              <div className="px-6 pt-3 pb-2 flex items-center space-x-4">
                <button className="p-2 text-white bg-[#4a4a4a] rounded-full">
                  <svg
                    ariaHidden="true"
                    focusable="false"
                    className="h-6 w-6 text-inherit fill-current overflow-visible"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                  >
                    <path d="M20 4H4C2.9 4 2.01 4.9 2.01 6L2 18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V6C22 4.9 21.1 4 20 4ZM19.6 8.25L12.53 12.67C12.21 12.87 11.79 12.87 11.47 12.67L4.4 8.25C4.15 8.09 4 7.82 4 7.53C4 6.86 4.73 6.46 5.3 6.81L12 11L18.7 6.81C19.27 6.46 20 6.86 20 7.53C20 7.82 19.85 8.09 19.6 8.25Z"></path>
                  </svg>
                </button>
                <div className="w-full">
                  <input
                    className="w-full italic text-inherit text-xl text-[#1A202C] font-light border-2 border-dotted border-transparent hover:border-black focus:border-black outline-none"
                    name="email"
                    type="email"
                    placeholder="Your E-mail address"
                    onChange={handleChange}
                    value={data.email}
                  />
                </div>
              </div>
            </li>
            <li>
              <div className="px-6 pt-3 pb-2 flex items-center space-x-4">
                <button className="p-2 text-white bg-[#4a4a4a] rounded-full">
                  <svg
                    ariaHidden="true"
                    focusable="false"
                    className="h-6 w-6 text-inherit fill-current overflow-visible"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                  >
                    <path d="M19.23 15.26L16.69 14.97C16.08 14.9 15.48 15.11 15.05 15.54L13.21 17.38C10.38 15.94 8.06004 13.63 6.62004 10.79L8.47004 8.94001C8.90004 8.51001 9.11004 7.91001 9.04004 7.30001L8.75004 4.78001C8.63004 3.77001 7.78004 3.01001 6.76004 3.01001H5.03004C3.90004 3.01001 2.96004 3.95001 3.03004 5.08001C3.56004 13.62 10.39 20.44 18.92 20.97C20.05 21.04 20.99 20.1 20.99 18.97V17.24C21 16.23 20.24 15.38 19.23 15.26V15.26Z"></path>
                  </svg>
                </button>
                <div className="w-full">
                  <input
                    className="w-full italic text-inherit text-xl text-[#1A202C] font-light border-2 border-dotted border-transparent hover:border-black focus:border-black outline-none"
                    name="phone"
                    type="tel"
                    placeholder="Your phone number"
                    onChange={handleChange}
                    value={data.phone}
                  />
                </div>
              </div>
            </li>
            <li>
              <div className="px-6 pt-3 pb-2 flex items-center space-x-4">
                <button className="p-2 text-white bg-[#4a4a4a] rounded-full">
                  <svg
                    ariaHidden="true"
                    focusable="false"
                    className="h-6 w-6 text-inherit fill-current overflow-visible"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                  >
                    <path d="M21 21H17V14.25C17 13.19 15.81 12.31 14.75 12.31C13.69 12.31 13 13.19 13 14.25V21H9V9H13V11C13.66 9.93 15.36 9.24 16.5 9.24C19 9.24 21 11.28 21 13.75V21ZM7 21H3V9H7V21ZM5 3C5.53043 3 6.03914 3.21071 6.41421 3.58579C6.78929 3.96086 7 4.46957 7 5C7 5.53043 6.78929 6.03914 6.41421 6.41421C6.03914 6.78929 5.53043 7 5 7C4.46957 7 3.96086 6.78929 3.58579 6.41421C3.21071 6.03914 3 5.53043 3 5C3 4.46957 3.21071 3.96086 3.58579 3.58579C3.96086 3.21071 4.46957 3 5 3V3Z"></path>
                  </svg>
                </button>
                <div className="w-full">
                  <input
                    className="w-full italic text-inherit text-xl text-[#1A202C] font-light border-2 border-dotted border-transparent hover:border-black focus:border-black outline-none"
                    name="linkedin"
                    type="text"
                    placeholder="Linkedin username"
                    onChange={handleChange}
                    value={data.linkedin}
                  />
                </div>
              </div>
            </li>
            <li>
              <div className="px-6 pt-3 pb-2 flex items-center space-x-4">
                <button className="p-2 text-white bg-[#4a4a4a] rounded-full">
                  <svg
                    ariaHidden="true"
                    focusable="false"
                    className="h-6 w-6 text-inherit fill-current overflow-visible"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                  >
                    <path d="M22 12.0609C22 6.50383 17.5234 2 12 2C6.47656 2 2 6.50383 2 12.0609C2 17.0835 5.65625 21.2454 10.4375 22V14.9691H7.89844V12.0609H10.4375V9.84437C10.4375 7.32325 11.9297 5.93005 14.2148 5.93005C15.3086 5.93005 16.4531 6.12655 16.4531 6.12655V8.60248H15.1914C13.9492 8.60248 13.5625 9.37866 13.5625 10.1745V12.0609H16.3359L15.8926 14.9691H13.5625V22C18.3438 21.2454 22 17.0835 22 12.0609Z"></path>
                  </svg>
                </button>
                <div className="w-full">
                  <input
                    className="w-full italic text-inherit text-xl text-[#1A202C] font-light border-2 border-dotted border-transparent hover:border-black focus:border-black outline-none"
                    name="facebook"
                    type="text"
                    placeholder="facebook username"
                    onChange={handleChange}
                    value={data.facebook}
                  />
                </div>
              </div>
            </li>
            <li>
              <div className="px-6 pt-2 pb-3 flex items-center space-x-4">
                <button className="p-2 text-white bg-[#4a4a4a] rounded-full">
                  <svg
                    ariaHidden="true"
                    focusable="false"
                    className="h-6 w-6 text-inherit fill-current overflow-visible"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                  >
                    <path d="M7.8 2H16.2C19.4 2 22 4.6 22 7.8V16.2C22 17.7383 21.3889 19.2135 20.3012 20.3012C19.2135 21.3889 17.7383 22 16.2 22H7.8C4.6 22 2 19.4 2 16.2V7.8C2 6.26174 2.61107 4.78649 3.69878 3.69878C4.78649 2.61107 6.26174 2 7.8 2V2ZM7.6 4C6.64522 4 5.72955 4.37928 5.05442 5.05442C4.37928 5.72955 4 6.64522 4 7.6V16.4C4 18.39 5.61 20 7.6 20H16.4C17.3548 20 18.2705 19.6207 18.9456 18.9456C19.6207 18.2705 20 17.3548 20 16.4V7.6C20 5.61 18.39 4 16.4 4H7.6ZM17.25 5.5C17.5815 5.5 17.8995 5.6317 18.1339 5.86612C18.3683 6.10054 18.5 6.41848 18.5 6.75C18.5 7.08152 18.3683 7.39946 18.1339 7.63388C17.8995 7.8683 17.5815 8 17.25 8C16.9185 8 16.6005 7.8683 16.3661 7.63388C16.1317 7.39946 16 7.08152 16 6.75C16 6.41848 16.1317 6.10054 16.3661 5.86612C16.6005 5.6317 16.9185 5.5 17.25 5.5ZM12 7C13.3261 7 14.5979 7.52678 15.5355 8.46447C16.4732 9.40215 17 10.6739 17 12C17 13.3261 16.4732 14.5979 15.5355 15.5355C14.5979 16.4732 13.3261 17 12 17C10.6739 17 9.40215 16.4732 8.46447 15.5355C7.52678 14.5979 7 13.3261 7 12C7 10.6739 7.52678 9.40215 8.46447 8.46447C9.40215 7.52678 10.6739 7 12 7V7ZM12 9C11.2044 9 10.4413 9.31607 9.87868 9.87868C9.31607 10.4413 9 11.2044 9 12C9 12.7956 9.31607 13.5587 9.87868 14.1213C10.4413 14.6839 11.2044 15 12 15C12.7956 15 13.5587 14.6839 14.1213 14.1213C14.6839 13.5587 15 12.7956 15 12C15 11.2044 14.6839 10.4413 14.1213 9.87868C13.5587 9.31607 12.7956 9 12 9Z"></path>
                  </svg>
                </button>
                <div className="w-full">
                  <input
                    className="w-full italic text-inherit text-xl text-[#1A202C] font-light border-2 border-dotted border-transparent hover:border-black focus:border-black outline-none"
                    name="instagram"
                    type="text"
                    placeholder="Instagram username"
                    onChange={handleChange}
                    value={data.instagram}
                  />
                </div>
              </div>
            </li>
          </ul>
        </main>
      </div>
    </div>
  );
}

export default Template1Editor;
